h2 {
    color: #ff4d4d;
    margin-bottom: 15px; /* Reduced margin */
    font-size: 1.5em; /* Reduced font size */
}

.settings-section {
    color: #ffffff;
    margin-bottom: 15px; /* Reduced margin */
    font-size: 0.9em; /* Reduced font size */
}

.settings-section h3 {
    margin-bottom: 8px; /* Reduced margin */
    font-size: 1.2em; /* Reduced font size */
}

.settings-section ul {
    list-style-type: none;
    padding-left: 0;
}

.settings-section ul li {
    border-radius: 4px;
    background-color: rgb(37, 37, 37);
    margin-bottom: 6px; /* Reduced margin */
    padding: 8px; /* Reduced padding */
    cursor: pointer;
    font-size: 0.9em; /* Reduced font size */
}

.settings-section ul li:hover {
    background-color: rgb(126, 126, 126);
}

.admin-settings-section {
    color: #ffffff;
    margin-bottom: 15px; /* Reduced margin */
    font-size: 0.9em; /* Reduced font size */
}

.admin-settings-section h3 {
    margin-bottom: 8px; /* Reduced margin */
    font-size: 1.2em; /* Reduced font size */
}

.admin-settings-section ul {
    list-style-type: none;
    padding-left: 0;
}

.admin-settings-section ul li {
    border-radius: 4px;
    background-color: rgb(37, 37, 37);
    margin-bottom: 6px; /* Reduced margin */
    padding: 8px; /* Reduced padding */
    cursor: pointer;
    font-size: 0.9em; /* Reduced font size */
}

.admin-settings-section ul li:hover {
    background-color: rgb(126, 126, 126);
}

.logout-button {
    background-color: #ff4d4d;
    color: white;
    padding: 8px 16px; /* Reduced padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin-top: 15px; /* Reduced margin */
    width: 100%;
    text-align: center;
    font-size: 1em; /* Reduced font size */
}

.logout-button:hover {
    background-color: #ff3333;
}
