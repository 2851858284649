.notification-container {
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.notification-container h1,
.notification-container h2 {
  color: white;
  text-align: center;
}

.notification-container input {
  width: calc(100% - 110px);
  border-radius: 5px;
  height: 5vh;
  background-color: #ffffff69;
  border: none; /* Removes the border */
  color: #fff;
  outline: none;
  font-size: 16px;
  padding: 10px; /* Adjust this value to move text closer to the top */
  box-sizing: border-box;
  margin-bottom: 5px;
}

.notification-container textarea {
  width: calc(100% - 110px);
  border-radius: 5px;
  height: 30vh;
  background-color: #ffffff69;
  border: none; /* Removes the border */
  color: #fff;
  outline: none;
  font-size: 16px;
  padding: 10px; /* Adjust this value to move text closer to the top */
  box-sizing: border-box;
}

.notification-container button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.notification-container ul {
  list-style-type: none;
  padding: 0;
}

.notification-container li {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-container li button {
  background-color: #ff4d4d;
  border-radius: 5px;
  padding: 5px 10px;
}
.tr_delete {
  width: 9%;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  border: 1px solid #00000031;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #00000038;
  color: rgb(65, 65, 65);
  text-align: center;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #ff1a1a;
}
