/* Main.css */
.main-container {
  width: 100vw;
  height: 100vh;
  color: white;
  text-align: center;
  position: relative; /* Ensure positioning context for absolute elements */
}

.background-grid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 80%;
  text-align: center;
  color: white;
  backdrop-filter: blur(6px);
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  overflow-y: auto; /* Allow vertical scrolling */
  box-sizing: border-box; /* Include padding and border in element’s width and height */
  padding: 20px; /* Ensure padding so content doesn’t touch edges */
}

.settings-icon {
  position: fixed;
  top: 20px; /* Adjust this value to position the icon vertically */
  right: 20px; /* Adjust this value to position the icon horizontally */
  cursor: pointer;
  z-index: 2000; /* Ensures it stays above other elements */
}

.user-management {
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  box-sizing: border-box; /* Ensure padding is included in width */
  overflow: auto; /* Enable scrolling */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
.user-management::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}

.user-management h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.user-table th,
.user-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #2e2e2e8e;
  color: #979797;
}
.user-password-input {
  color: #ffffff;
  border: none;
  outline: none;
  background-color: #dddddd00;
}
.user-password-button {
  background-color: #dddddd1a;
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
}
.user-password-button:hover {
  background-color: #dddddd4f;
}

.user-table tr:hover {
  background-color: #3131314d;
}

.button-management {
  background-color: #dddddd1a;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-management:hover {
  background-color: #dddddd4f;
}

.button-delete {
  background-color: #dddddd1a; /* Red background for delete */
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-delete:hover {
  background-color: #dddddd4f; /* Darker red on hover */
}
