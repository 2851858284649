@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../asset/backimage2.jpg");
  background-size: cover;
}

.login-container {
  width: 420px;
  height: 100%;
  max-width: 420px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.login-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.form-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.form-container.active {
  opacity: 1;
  transform: translateX(0);
}

.form-container.exit {
  opacity: 0;
  transform: translateX(-100%);
}

.login-box h2 {
  color: #fff;
  font-size: 36px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 700;
}
.input-container {
  width: 100%;
  height: 50px;
  margin: 15px 0;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 25px;
  background: transparent;
  color: #fff;
}

.login-input-field {
  width: 95%;
  height: 100%;
  background-color: transparent;
  border: none; /* Removes the border */
  color: #fff;
  outline: none;
  font-size: 16px;
}

.login-input-field::placeholder {
  color: #fff;
}

.input-field {
  width: 80%;
  height: 100%;
  background-color: transparent;
  border: none; /* Removes the border */
  color: #fff;
  outline: none;
  font-size: 16px;
}

.input-field::placeholder {
  color: #fff;
}

.login-button {
  width: 100%;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-top: 15px;
  transition: background 0.3s ease, color 0.3s ease;
}

.login-button:hover {
  background: rgba(83, 73, 73, 0.7);
  color: #fff;
}

.register-button {
  width: 100%;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-top: 15px;
  transition: background 0.3s ease, color 0.3s ease;
}

.register-button:hover {
  background: rgba(83, 73, 73, 0.7);
  color: #fff;
}
.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 10px;
    width: auto;
  }
  
  label {
    font-size: 14px;
  }
  .details-button {
    margin-left: 10px;
    background: none;
    border: none;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
  
  .details-button:hover {
    color: #0056b3;
  }
  
  .details-content {
    background: #f9f9f912;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  .details-content p {
    margin: 0px;
  }
  
.Verification-button {
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
  border-radius: 10px;
  background: #fff;
  width: auto;
  height: 100%;
  color: #333;
  transition: background 0.3s ease, color 0.3s ease;
}

.Verification-button:hover {
  background: rgba(83, 73, 73, 0.7);
  color: #fff;
}

.forgot-signup {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.forgot-password {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: right; /* Align text to the right */
  width: 100%; /* Make sure the button takes up full width, adjust as necessary */
  display: block; /* Ensures the button behaves like a block-level element */
}

p {
  color: #fff;
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}
.signup {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
}

.forgot-password:hover,
.signup:hover {
  color: #aaa;
  text-decoration: underline;
}

.back-to-login {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 25px;
  transition: color 0.3s ease;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: center; /* Align text to the right */
  width: 100%; /* Make sure the button takes up full width, adjust as necessary */
  display: block; /* Ensures the button behaves like a block-level element */
}

.back-to-login:hover {
  color: #aaa;
  text-decoration: underline;
}

.login-message {
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
}
