/* Basic reset and styling for the entire dashboard */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 2.5rem;
  color: #4b4b4b;
  margin-bottom: 10px;
}

.dashboard-header p {
  font-size: 1.2rem;
  color: #6c757d;
}

.dashboard-main {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .dashboard-header h1 {
    font-size: 2rem;
  }

  .dashboard-header p {
    font-size: 1rem;
  }

  .dashboard-main {
    padding: 15px;
  }
}
