.Notification-modal-content {
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Ensure it is above the overlay */
}

.Notification-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
