.main-container {
  padding: 20px;
}

.background-grid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 80%;
    text-align: center;
    color: white;
    backdrop-filter: blur(6px);
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(255,255,255,0.2);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.email-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}

.email-form label {
  margin-bottom: 10px;
}

.email-form input,
.email-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.email-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-form button:hover {
  background-color: #0056b3;
}

.status-message {
  margin-top: 10px;
  color: green;
}

.status-message.error {
  color: red;
}
