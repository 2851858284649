.draggable-modal {
  color: #000;
  position: absolute;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  z-index: 1000;
  top: 50px; /* Initial position */
  left: 50px; /* Initial position */
  transform: scale(0.9);
  transform-origin: top left;
}

.modal-header {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  padding: 10px;
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: aliceblue;
}

.close-button:hover {
  color: #ff3333; /* Change the color on hover */
}

.modal-content {
  padding: 20px;
  max-height: auto;
  overflow-y: auto;
}
