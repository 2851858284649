.chat-page {
  display: flex;
  height: 100vh;
  width: 70vw;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

/* Left Panel (Contacts) */
.contact-list {
  width: 30%;
  border-right: 1px solid #ccc;
  padding: 10px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.contact-list h2 {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}

.contact-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
}

.contact-list li {
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-list li:hover {
  background-color: #e6f7ff;
}

.add-friend {
  margin-top: 20px;
  text-align: center;
}

.add-friend input {
  width: 80%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.add-friend button {
  width: 15%;
  padding: 8px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-friend button:hover {
  background-color: #45a049;
}

/* Right Panel (Chat) */
.chat-section {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.chat-history {
  flex-grow: 1;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  overflow-y: auto;
}

.chat-history h2 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.chat-history ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-history li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.chat-history li strong {
  font-weight: bold;
}

.chat-input {
  padding: 10px;
  display: flex;
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
}

.chat-input input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  width: 15%;
  margin-left: 5%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 14px;
}