@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.background-grid-resetpassword {
  width: 420px;
  height: 100%;
  max-width: 420px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
  color: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.background-grid-resetpassword h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #ffffff; /* Bright red color for the heading */
  font-weight: 600;
}
.reset-password-form {
  display: flex;
  flex-direction: column;
}

.reset-password-form input {
  width: 100%;
  height: 50px;
  margin: 15px 0;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 25px;
  background: transparent;
  color: #fff;
  outline: none;
}

.reset-password-form input::placeholder {
    color: #ffffff;
}

.reset-password-form button {
  width: 100%;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-top: 15px;
}
.error-button {
  width: 100%;
  padding: 10px;
  background: #ff0000; /* Bright red color for the login button */
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
}

.reset-password-form button:hover {
  background: rgba(83, 73, 73, 0.7);
  color: #fff;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

.success-message {
  color: green;
  margin-bottom: 15px;
}
