body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-size: cover;
  }
  
  .myapge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .myapge-content-box {
    background-color: rgba(0, 0, 0, 0.432); /* Transparent white background */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 500px;
    width: 100%;
  }
  
  .myapge-h1, .myapge-h2 {
    color: #f2f2f2; /* Bright white */
    margin-bottom: 20px;
  }
  
  .myapge-p {
    color: #e6e6e6; /* Slightly darker than white */
  }
  
  .myapge-ul {
    list-style: none;
    padding: 0;
  }
  
  .myapge-li {
    margin-bottom: 10px;
    color: #cccccc; /* Light gray */
    padding: 10px;
    border: 1px solid #e6e6e6; /* Light border */
    border-radius: 5px; /* Rounded corners */
    display: flex;
    justify-content: space-between; /* Space between strong text and data */
  }
  
  .myapge-li strong {
    color: #ffffff; /* Pure white for emphasis */
    margin-right: 10px; /* Spacing between strong and data */
  }
  
  .myapge-error-message, .myapge-loading-message {
    color: #ffcccc; /* Light red for error messages */
    text-align: center;
    margin-top: 20px;
  }
  .myapge-toggle-button {
    background-color: #98989819;
    color: #ffffff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
  }
  
  .myapge-toggle-button:hover {
    background-color: #ffffff19;
  }
  