/* UserNotification.css */

.user-notification-container {
  width: 90%;
  height: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-notification-container h2 {
  text-align: center;
  font-size: 24px;
  color: #d4d4d4;
  margin-bottom: 20px;
}

.user-notification-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-notification-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.user-notification-item:last-child {
  border-bottom: none;
}

.user-notification-title {
  background-color: #ffffff21;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  text-align: left;
}

.user-notification-title:hover {
  background-color: #ffffff69;
}

.user-notification-content {
  background-color: #4d4d4d56;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.user-notification-content p {
  margin: 5px 0;
  font-size: 16px;
  color: #d4d4d4;
}

.user-notification-content strong {
  color: #333;
}

.user-notification-content pre {
  text-align: start;
  background-color: #4d4d4d56;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  font-size: 14px;
  line-height: 1.5;
  color: #d4d4d4;
}
