body,
html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-family: "Arial", sans-serif;
  background-color: #121212;
  user-select: none;
}
